@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.sa {
    width: 100%;
    height: 70vh;
    background: rgba(2, 2, 2, 0.158);
    position: relative;
  }
  
  .sa:before {
    content: '';
    position: absolute;
    background: url('../../assets/sa-submenu-bg.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  


.sa .sa-content {
    width: 40%;
    display: block;
    position: absolute;
    bottom: 20%;
    right: 5%;
    padding: 1rem;
}

.sa h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .sa .sa-content {
        width: 100%;
        display: block;
        position: absolute;
        right: 0%;
        padding: 1rem;
    }

.sa h1 {
    font-size: 2.5rem;
}
}


@media screen and (max-width:700px) {
    .sa {
        height: 100vh;
      }
      
      .sa .sa-content {
        bottom: 30%;
    }
    

}
