.therapySA {
    width: 100%;
    height: 100%;
    background: #24242500;
    position: relative;
  }
  
  .therapySA:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg1.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.05;
  }


  .therapySA-content {
    padding: 3rem 0;
    height: auto;
  }

.therapySA-header {
    width: 100%;
    text-align: center;
}

.therapySA-header h1 {
    padding: 1rem 0;
    letter-spacing: 2px;
    font-size: 1.2rem;
}

.therapySA-header p {
  padding: 0 1rem;
}

.therapySA-flex {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0;
  gap: 1rem;
  justify-content: center;
}


.therapySA-flexItem {
  width: 370px;
  height: auto;
  border: 1px solid rgba(107, 62, 150, 0.158);
  padding: 0 0 2rem 0;
  margin: 1rem;
}

.therapySA-flexItem .flex-img {
  width: 369px;
}

.therapySA-flexItem .flex-img img {
  width: 100%;
}

.therapySA-flexItem .flex-caption h1{
  font-size: 1.2rem;
  padding: 1rem 0 0 0;
  text-align: center;
}


.therapySA-flexItem .flex-caption p{
  padding: 1rem;
  text-align: center;
}

.therapySA-btn {
  margin: auto;
  text-align: center;
}

.therapySA-btn button {
  padding: .8rem 2rem;
  font-size: 1rem;
  border-radius: 10px;
}

.therapySA-btn button:hover {
  padding: .8rem 2rem;
  font-size: 1rem;
  border-radius: 10px;
  background-color: rgba(49, 29, 85, 0.514);
  border: none;
}