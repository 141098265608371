@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.footer {
    width: 100%;
    height: auto;
    position: relative;
    background: #5a5c82;
}


.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
}

.logo-footer {
    width: 100px;
}

.logo-footer img {
    width: 100%;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer {
    display: flex;
    align-items: center;
}

.footer .top .footer-icon {
    font-size: 5rem;
    margin-right: 1rem;
    color: white;
    cursor: pointer;
    padding: .8rem;
    border: 1px solid white;
    border-radius: 10rem;
}


.footer .top .footer-icon2 {
    font-size: 3rem;
    margin-right: 1rem;
    color: white;
    cursor: pointer;
}

.footer h1 {
    font-size: 2rem;
    color: white;
}

.footer h2 {
    font-size: 2rem;
}

.footer h3 {
    color: white;
    font-size: 1rem;
}

.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
}

.footer-col {
    width: 300px;
}

.footer h3 {
    margin: 1rem 0;
    text-transform: uppercase;
    color: rgb(226, 140, 10);
    font-size: .8rem;
    text-align: center;

}

.footer .license p {
    color: white;
    text-align: center;

}

.footer .license img {
    margin: 1rem 0;
}


.footer .license a:hover {
    color: rgb(8, 138, 245);
}

.footer p {
    font-family: 'Montserrat', sans-serif;
    margin: .5rem 0;
    color: white;
    font-weight: 500;
    text-align: center;
}


.copyright p {
    color: white;
    text-transform: uppercase;
}


.footer .top .footer-icon2 {
    display: none;
}


.certification {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.certificationLeft {
    width: 40%;
    text-align: center;
    margin: auto;

}

.certificationRight {
    width: 40%;
    padding: 1rem;
    margin: auto;

}


.certification h3 {
    text-align: center;
    font-size: .8rem;
}

.certification p {
    text-align: center;
    color: white;
}

.certificationLeftImage {
    width: 200px;
    margin: auto;
}

.certificationLeftImage img{
    width: 100%;
}

.certificationRightImage {
    width: 300px;
    margin: auto;
}

.certificationRightImage img{
    width: 100%;
}



.certification a:hover {
    text-align: center;
    color: rgb(10, 208, 235);
}


@media screen and (max-width: 940px) {
    .certification {
        flex-wrap: wrap;
    }

    .certificationRight {
        width: 100%;
    }

    .certificationLeft {
        width: 100%;
    }
    

}




@media screen and (max-width: 1200px) {
    .footer {
        height: 100%;
    }



    .footer-col h3, .footer-col p {
        text-align: center;
    }


    .footer .top .footer-icon {
        display: none;
}


.logo-footer {
    margin: auto;
}



}

