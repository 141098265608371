@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.residence {
    width: 100%;
    height: 70vh;
    background: #2424254b;
    position: relative;
  }
  
  .residence:before {
    content: '';
    position: absolute;
    background: url('../../assets/submenu-bg-residence.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
.residence {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.residence .residence-content {
    width: 60%;
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.residence h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
    padding: .5rem 0;
}

.residence p {
    color: white;
    text-align: center;
}





/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.residence h1 {
    font-size: 2rem;
}

.residence .residence-content {
    width: 100%;
}
}



@media screen and (max-width:700px) {
    .residence {
        height: 100vh;
    }

    .residence .residence-content {
        bottom: 30%;
    }
}