@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.community {
    width: 100%;
    height: 70vh;
    position: relative;
    background: #00000069;
  }
  
  .community:before {
    content: '';
    position: absolute;
    background: url('../../assets/community.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


.community .community-content {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 20%;
    padding: 1rem;
    padding: 1rem;
}

.community h1 {
    font-size: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
    text-transform: uppercase;
}

.community span {
    color: goldenrod;
}
.community p {
    text-align: center;
    color: white;

}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.community h1 {
    font-size: 2rem;
}
}



@media screen and (max-width:700px) {

    .community {
        height: 100vh;
      }

    .community h1 {
        font-size: 1.5rem;
    }

    .community p {
        font-size: 12px;
    }

    .community .community-content {
        bottom: 30%;
    }

    }