@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.wrapper-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 1rem;
    padding: 5rem 1rem;
}

.wrapper-content-left {
    width: 450px;
    text-align: center;
    margin: auto;
}

.wrapper-content-left img  {
    width: 100%;
}

.wrapper-content-right {
    width: 45%;
    margin: auto;
}


.wrapper-content-right h2 {
    font-size: 2rem;
    color: #5a5c82;
}

.wrapper-content-right h1 {
    text-transform: uppercase;
    color: #977ab3;
    font-size: 1rem;
    letter-spacing: 8px; 
}

.wrapper-content-right p {
    text-align: justify;
}


.leaf {
    position: absolute;
    width: 35%;
    left: -5%;
    bottom: -5%;
}



@media screen and (max-width:940px)  {

    .wrapper-content {
        flex-wrap: wrap;
    }

    .wrapper-content-left {
        width: 80%;
    }

    .wrapper-content-right {
        width: 100%;
        padding: 1rem 2rem;
    }

    .wrapper-content-right h1, .wrapper-content-right h2{
        text-align: center;
    }

    .wrapper-content-right h2 {
        font-size: 1.5rem;
    }

}

