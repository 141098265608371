@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.mission {
    width: 100%;
    height: 70vh;
    position: relative;
    background: #00000050;
  }
  
  .mission:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-mission.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.mission .mission-content {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}


.mission h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.mission h2 {
    color: white;
    font-size: 1.5rem;
}

.mission h1 {
    font-size: 3rem;
}
}

@media screen and (max-width:700px) {
    .mission {
        height: 100vh;
      }

      .mission .mission-content {
        bottom: 30%;
    }
}