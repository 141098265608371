@import url('https://fonts.googleapis.com/css2?family=Playfair&display=swap');

.fling-minislide { 
  display:block; 
  width:100%; 
  height:100vh; 
  overflow:hidden; 
  position:relative; 
  background-color:#000000;
}

.fling-minislide img { 
  position:absolute; 
  animation:fling-minislide 32s infinite, zoom-in 32s infinite; 
  opacity:0;  
  object-fit:cover; 
  width:100vw; 
  height:100%;
} 
  
  /**/

@keyframes fling-minislide {28%{opacity:1;} 32%{opacity:0;}}
.fling-minislide img:nth-child(4){animation-delay:0s;}
.fling-minislide img:nth-child(3){animation-delay:8s;}
.fling-minislide img:nth-child(2){animation-delay:16s;}
.fling-minislide img:nth-child(1){animation-delay:24s;}

@keyframes zoom-in{0%{transform:scale(1) translateY(0);transform-origin:50% 84%}100%{transform:scale(1.5) translateY(15px);transform-origin:center}}

.title-wrapper {
  background-color: rgba(0, 0, 0, 0.404);
}

.hero {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.rate {
  text-align: center;
  margin: auto;
}

.rate p {
  padding: 0 1rem;
  color: white;
}

.rate h4 {
  font-size: 4rem;
  color: white;
  padding: 0;
}

.rate h3 {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 2rem;
  text-transform: uppercase;
}


.rate h3:after {
	height: 4px;
	width: 40%;
	content: "";
	background: var(--primary-color);
	display: block;
	transition: width 0.4s ease;
	transition-delay: 0.8s;
  margin: auto;
}


.title-wrapper {
  position: absolute;
  padding: 2rem;
  width: 100%;
  height: 100%;
}

.title-wrapper span {
    color: white
}

.title-wrapper h1 {
  font-size: 5rem;
  color: white;
  letter-spacing: 5px;
  font-family: 'Playfair', serif;
}

.title-wrapper h2 {
  color: var(--secondary-color);
  letter-spacing: 5px;
  font-size: 1rem;
  padding: 1rem 0;
}

.title-wrapper h3 {
  color: white;
  text-align: center;
  letter-spacing: 1.5px;
}

.hero-box {
  position: relative;
  width: 50%;
  top: 20%;
  left: 5%;
}

.hero-image {
  width: 500px;
  margin: auto;
  padding: 1rem;
}

.hero-image img {
  width: 100%;
}

.hero-btn {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.hero-btn button {
  font-family: 'Roboto', sans-serif;
  padding: .7rem 5rem;
  margin: 1rem 0;
  background-color: var(--primary-color);
  font-family: 'Playfair', serif;
  border: none;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  cursor:pointer;
  border-radius: 5px;
}

.hero-btn button:hover {
    background-color: rgba(252, 252, 252, 0.918);
    color: var(--secondary-color);
}

.hero-btn2 button {
  font-family: 'Roboto', sans-serif;
  padding: .7rem 5rem;
  margin: 1rem 0;
  background-color: rgba(255, 255, 255, 0);
  font-family: 'Playfair', serif;
  border: 1px solid var(--primary-color);
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  cursor:pointer;
}

/* MEDIA QUERIES */

@media screen and (max-width:1300px) {

  .hero .title-wrapper{
    left: 0%;
    text-align: center;
    width: 100%;
}

    .hero h2 {
        font-size: 18px;
    }

    .hero h1 {
    font-size: 4rem;
    }

    .hero p {
        font-size: 16px;
    }

    .hero-btn {
      justify-content: center;    
    }

}

@media screen and (max-width:1200px) {
  .hero-box {
    position: relative;
    width: 100%;
    top: 20%;
    left: 0%;
  }
}


@media screen and (max-width:940px) {

  .hero h2 {
      font-size: 1rem;
  }

  .hero h1 {
  font-size: 3rem;
  line-height: 2.5rem;
  padding: 1rem 0;
  }

  .title-wrapper h3 {
    font-size: 1rem;
  }

  .hero-btn button, .hero-btn2 button {
    padding: .7rem 3rem;
  }

  .fling-minislide { 
    height:100vh; 
  }

}


@media screen and (max-width:700px) {


  .hero-btn button {
    padding: .5rem 2rem;
    margin: 1rem 0;
    font-size: .8rem;
  }
  
  .hero-btn2 button {
    padding: .5rem 2rem;
    margin: 1rem 0;
    font-size: .8rem;
  }

  .hero h2 {
    font-size: .8rem;
}

.hero-image {
  width: 300px;
}


.rate h3:after {
	width: 70%;
}


}



@media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {

  .rate {
    display: none;
  }

}