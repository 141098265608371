@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.treatment {
    width: 100%;
    height: 70vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #0a0a0a71;
  }
  
  .treatment:before {
    content: '';
    position: absolute;
    background: url('../../assets/treatment-hero.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .treatment-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.treatment h1 {
    color: white;
    font-size: 4rem;
}

.treatment p {
    color: white;
    font-size: 16px;
    text-align: center;
}



/* MEDIA QUERIES - HERO */

@media screen and (max-width:940px) {

.treatment h1 {
    font-size: 2.5rem;
    text-align: center;
}

}


@media screen and (max-width:700px) {

  .treatment {
      height: 100vh;
  }

  .treatment .treatment-content {
    bottom: 30%;
}
  
}

