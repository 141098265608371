@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.jobs {
    width: 100%;
    height: 70vh;
    position: relative;
    background: #00000036;
  }
  
  .jobs:before {
    content: '';
    position: absolute;
    background: url('../../assets/submenu-bg-jobs.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


.jobs .jobs-content {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 20%;
    padding: 1rem;
    padding: 1rem;
}

.jobs h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;

}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.jobs h1 {
    font-size: 2rem;
}
}



@media screen and (max-width:700px) {

    .jobs {
        height: 100vh;
      }

    .jobs .jobs-content {
        bottom: 30%;
    }
    }